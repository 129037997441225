import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  readonly progress = new BehaviorSubject<number>(0);
  readonly loading$: Observable<number> = this.progress.asObservable().pipe(shareReplay(1));

  set loading(activeCalls: number) {
    this.progress.next(activeCalls);
  }
}
