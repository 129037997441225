import { Routes } from '@angular/router';
import { AuthGuard, LoginComponent } from '@ess-front/authorization';
import { BaseComponent } from '@app/core/components/base/base.component';

export const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'countries',
        loadComponent: () =>
          import('@features/countries/ui/regions/regions.component').then(mod => mod.RegionsComponent),
      },
      {
        path: 'countries/:id',
        loadComponent: () =>
          import('@features/countries/ui/country/country.component').then(mod => mod.CountryComponent),
      },
      {
        path: 'destinations',
        loadChildren: () => import('@ess-front/destinations').then(mod => mod.DestinationsModule),
      },
      {
        path: 'billing',
        loadChildren: () => import('@ess-front/billing').then(mod => mod.BillingModule),
      },
    ],
  },
  {
    path: 'not-found',
    loadComponent: () => import('@features/not-found/ui/not-found.component').then(mod => mod.NotFoundComponent),
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];
