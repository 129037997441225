<mat-sidenav-container fullscreen>
  <!-- SIDE MENU -->
  <mat-sidenav #SideNavStart class="sidenav_right" mode="side" position="start">
    <mat-nav-list class="menu">
      <a (click)="SideNavStart.close()" [routerLink]="['/countries']" class="menu_item" mat-list-item>
        <mat-icon matListItemIcon> place </mat-icon>
        <h4 matListItemLine>Destinations</h4>
      </a>

      <a (click)="SideNavStart.close()" [routerLink]="['/billing']" class="menu_item" mat-list-item>
        <mat-icon matListItemIcon> receipt </mat-icon>
        <h4 matListItemLine>Billing</h4>
      </a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- TOP MENU -->
    <mat-toolbar>
      @if (SideNavStart.opened) {
        <button (click)="SideNavStart.close()" color="primary" type="button" mat-icon-button>
          <mat-icon>close</mat-icon>
        </button>
      } @else {
        <button (click)="SideNavStart.open()" color="primary" type="button" mat-icon-button>
          <mat-icon>menu</mat-icon>
        </button>
      }
      <img alt="logo" class="logo" src="assets/logos/black-logo-transparent-background.png" />
      <ess-user-menu />
    </mat-toolbar>

    <main>
      <div class="main_container">
        <router-outlet />
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
